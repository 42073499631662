<template>
  <div style="background-color: white">
    <section-item height="600px" :style="{backgroundImage: 'url(' + require('@/assets/banner/about-banner2.png') + ')' }"></section-item>
    <section-item title="公司简介" subtitle="Company Introduction" height="750px" bgColor="#ffffff">
      <div class="company-profile">
        <img class="img" :src="require('@/assets/introduce-img1.png')"/>
        <div class="content">
          <div style="font-size: 24px">浙江沐恩网络科技有限公司</div>
          <div style="margin-top: 60px">
            浙江沐恩网络科技有限公司成立于2018年9月，是一家从事移动互联网软件开发和销售的公司，核心员工都是出自移动互联网知名企业。
          </div>
          <div style="margin-top: 50px">公司主营功能机和智能机业务，公司和多个芯片设计公司、手机方案设计公司都有深入的业务合作关系，在技术和市场方面都有明显优势。业务遍及国内和海外60多个国家及地区。
          </div>
          <div style="margin-top: 50px">
            期望通过我们创造性的技术让越来越多的手机用户享受移动互联网生活的精彩！
          </div>
        </div>
      </div>
    </section-item>
    <section-item height="600px" :style="{backgroundImage: 'url(' + require('@/assets/introduce-img2.png') + ')' }"></section-item>
    <section-item height="620px" bgColor="#ffffff">
      <img class="img" :src="require('@/assets/introduce-img3.png')" height="100%"/>
    </section-item>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
<style lang="scss" scoped>
// 公司简介
.company-profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  .img {
    width: 1000px;
    height: 400px;
    right: 600px;
    top: -20px;
    position: absolute;
  }
  .content {
    height: 400px;
    width: 495px;
    font-size: 16px;
  }
}
//::v-deep .block{
//  background-size: 100%;
//}
</style>